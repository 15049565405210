<template>
  <v-row>
    <v-col
      cols="1"
      align-self="center"
      style="max-width: 4.333%"
    >
      <v-icon> mdi-home-group </v-icon>
    </v-col>
    <v-col>
      <v-combobox
        v-model="newProjectState"
        :items="projectStates"
        item-disabled="disable"
        item-text="name"
        color="primary"
        label="Projektstatus wählen..."
      />
    </v-col>
    <v-col>
      <DatePicker
        label="Datum für den Baustart"
        :value="showConstructionStartDate"
        disableWeekend
        disableHolidays
        :disabled="
          newProjectState.name !=
            projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value
        "
        @input="updateConstructionStartDate($event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import DatePicker from '@/components/elements/DatePicker.vue';

export default {
  name: 'UpdateProjectStatus',
  components: {
    DatePicker
  },
  data: () => ({
    addresses: undefined,
    toggles: [],
    activeItem: {},
    city: null,
    district: [],
    districtItems: [],
    city3: null,
    cities: [],
    city2: undefined,
    cities2: [],
    cityItems2: undefined,
    timer: null,
    loading: false,
    isUpdating: false,
    search: '',
    loadingCities: false,
    loadingDistricts: false
    // selectAllDistricts: false
  }),
  computed: {
    showConstructionStartDate() {
      return this.newProjectState.name ===
        this.projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value
        ? this.constructionStartDate
        : undefined;
    },
    caption() {
      return `Adressen deren Baustatus zu ${this.propertyState.join(
        ', '
      )} geändert werden soll`;
    },
    username() {
      return this.$auth.user().userName;
    },
    userGroups() {
      return this.$auth.user().userRoles;
    }
  },
  watch: {
    projectItem: function () {
      this.changeRequestFlag = false;
      this.clear();
    },
    currentProjectState: {
      handler: function (newValue, oldValue) {
        if (newValue.name) {
          this.findPossibleProjectStates();
        }
      },
      deep: true
    }
  },
  created() {
    this.projectStateConstants = {
      NOT_SET: {
        value: 'Nicht gesetzt'
      },
      PROJECT_PLANNED: {
        value: 'Projekt geplant'
      },
      PROJECT_READY: {
        value: 'Projekt abgeschlossen'
      },
      PROJECT_CANCELED: {
        value: 'Projekt abgebrochen'
      },
      PROJECT_CONSTRUCTION_STARTED: {
        value: 'Bau gestartet'
      }
    };
  },
  methods: {
    updateConstructionStartDate(newDate) {
      this.constructionStartDate = newDate;
    },
    rowClicked(item, row) {
      row.expand(!row.isExpanded);
    },
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    row_classes(item) {
      if (item.match === 1) {
        return 'highlighted-row';
      }
    },
    updateProjektItem(projectItem) {
      this.projectItem = projectItem;
    },
    findPossibleProjectStates() {
      this.projectStates = [];
      // Admin is allowed to change to any state at any time
      if (this.userGroups.includes('camunda-admin')) {
        this.projectStates.push({
          name: this.projectStateConstants.NOT_SET.value,
          disable: true
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_PLANNED.value,
          disable: false
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value,
          disable: false
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_READY.value,
          disable: false
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_CANCELED.value,
          disable: false
        });
      } else {
        this.projectStates.push({
          name: this.projectStateConstants.NOT_SET.value,
          disable: true
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_PLANNED.value,
          disable:
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_PLANNED.value ||
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value ||
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_READY.value ||
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_CANCELED.value
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value,
          disable:
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value ||
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_READY.value ||
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_CANCELED.value
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_READY.value,
          disable:
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_READY.value ||
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_CANCELED.value
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_CANCELED.value,
          disable: false
        });
      }
    },
    getConstructionStartDate(currentProjectStartDate) {
      var dateString;
      if (
        currentProjectStartDate !== '' &&
        currentProjectStartDate != null &&
        currentProjectStartDate !== undefined
      ) {
        const dateInMilliSec = Date.parse(currentProjectStartDate);
        const date = new Date(dateInMilliSec);
        dateString =
          date.getFullYear() +
          '-' +
          String(date.getMonth() + 1).padStart(2, '0') +
          '-' +
          String(date.getDate()).padStart(2, '0');
      }
      return dateString;
    },
    getCurrentProjectState() {
      HTTP.get(
        `past/projects?where[projektId][equals]=${this.projectItem.projektId}`
      )
        .then((result) => {
          this.currentProjectStartDate = result.data[0].startDate;
          this.constructionStartDate = this.getConstructionStartDate(
            this.currentProjectStartDate
          );
          this.currentProjectState = {
            name:
              result.data[0].projectStatus.description !== null ||
              result.data[0].projectStatus.description !== ''
                ? result.data[0].projectStatus.description
                : this.projectStateConstants.NOT_SET.value,
            disable: true
          };
          this.newProjectState = this.currentProjectState;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              `Der Projektstatus von Projekt ${this.projectItem.projektId} konnte nicht ermittelt werden.`
            )
          )
        );
    },
    getFtthAddressesByProject() {
      this.loadingAddresses = true;
      this.clear();
      HTTP.get(
        `/projectConstructionState/addresses/projectBulk?projectId=${this.projectItem.projektId}`
      )
        .then(({ data }) => {
          this.projectState = data.projectState;
          this.data = data.propertyConstructionStateDtoList;
          this.propertiesWithoutProject = data.propertyWithoutProjectDtos;
          this.filteredData = this.data;
          this.projectCity = this.data[0].address.city;
          this.projectDistrict = this.data[0].address.district;
          this.data.forEach((element) => {
            this.ags27Ids.push(element.ags27Id);
          });
          this.getAllPropertyStatusByAgs27IdBulk();
          this.getCurrentProjectState();
          this.getProjectStatuses();
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Adressen konnten nicht geladen werden'
            )
          )
        )
        .finally(() => {
          this.loadingAddresses = false;
        });
    },
    getAllPropertyStatusByAgs27IdBulk() {
      this.loadingPropertyStatus = true;
      HTTP.post('/order/propertyStatus', this.ags27Ids)
        .then((result) => {
          this.propertyResults = result.data.propertyResults;
          this.propertyResults.forEach((property) => {
            this.data.forEach((entry) => {
              if (entry.ags27Id === property.ags27) {
                entry.houseConnectionBuild = property.houseConnectionBuilt;
                entry.houseConnectionPatched = property.houseConnectionPatched;
                entry.popOnline = property.popOnline;
              }
            });
          });
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Der Liegenschaftsstatus der Adressen konnte nicht geladen werden'
            )
          )
        )
        .finally(() => {
          this.loadingPropertyStatus = false;
        });
    },
    changeRequestOverview() {
      this.changeRequestFlag = !this.changeRequestFlag;
      this.expandedRows = [];
    },
    getSelectedItems(values) {
      this.checkedAddresses = values.map(function (value) {
        return value;
      });
    },
    getFiltered() {
      if (this.$refs.dataTable) {
        this.filteredData = this.$refs.dataTable.$children[0].filteredItems;
      }
    },
    createPropertyChangeRequest() {
      const today = new Date().toISOString();
      const propertyConstructionStateDtos = this.checkedAddresses;
      const projectConstructionStateChangeDataDto = {
        user: this.username,
        userGroups: this.userGroups,
        houseconnectionBuilt: this.houseconnectionBuild,
        housePatched: this.housePatched,
        popOnline: this.popOnline,
        projectId: this.projectItem.projektId,
        houseConnectionBuiltDate: this.houseconnectionBuild ? today : null,
        houseConnectionPatchedDate: this.housePatched ? today : null,
        popOnlineDate: this.popOnline ? today : null
      };
      HTTP.post('/projectConstructionState/changePropertyState', {
        projectConstructionStateChangeDataDto,
        propertyConstructionStateDtos
      })
        .then(() => {
          this.clear();
          this.changeRequestFlag = !this.changeRequestFlag;
          this.showMessage(
            'success',
            'Die Liegenschaftsstatusänderungen wurden zur Überprüfung freigegeben.'
          );
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Etwas ist schief gelaufen'
            )
          )
        );
    },
    getProjectStatuses() {
      HTTP.get('past/projectStatuses')
        .then((response) => {
          response.data.forEach((dataEntry) => {
            this.projectStatusIdMap.set(dataEntry.description, dataEntry);
          });
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Projektstatus IDs konnten nicht geladen werden'
            )
          )
        );
    },
    createProjectChangeRequest() {
      if (
        this.newProjectState.name !== this.projectStateConstants.NOT_SET.value
      ) {
        console.log(this.newProjectState, this.oldProjectState);
        const oldProjectState = this.projectStatusIdMap.get(this.projectState);
        const newProjectState = this.projectStatusIdMap.get(
          this.newProjectState.name
        );
        const propertyConstructionStateDtos = this.data;
        const projectConstructionStateChangeDataDto = {
          user: this.username,
          userGroups: this.userGroups,
          oldProjectStateDto: oldProjectState,
          newProjectStateDto: newProjectState,
          projectId: this.projectItem.projektId,
          constructionStart:
            this.newProjectState.name ===
            this.projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value
              ? new Date(this.constructionStartDate).toISOString()
              : this.currentProjectStartDate,
          city: this.projectCity,
          district: this.projectDistrict
        };
        HTTP.post('/projectConstructionState/changeProjectState', {
          projectConstructionStateChangeDataDto,
          propertyConstructionStateDtos
        })
          .then(() => {
            this.clear();
            this.showMessage(
              'success',
              'Die Projektstatusänderung wurde zur Überprüfung freigegeben.'
            );
          })
          .catch((err) =>
            this.showMessage(
              'error',
              ErrorMessageBuilder.buildErrorMessage(
                err.response.data,
                'Etwas ist schief gelaufen'
              )
            )
          );
      } else {
        this.showMessage(
          'error',
          ErrorMessageBuilder.buildErrorMessage([], 'Ungültiger Projektstatus')
        );
      }
    },
    clear() {
      this.data = [];
      this.checkedAddresses = [];
      this.ags27Ids = [];
      this.filteredData = [];
      this.expandedRows = [];
      this.propertyState = [];
      this.popOnline = false;
      this.housePatched = false;
      this.houseconnectionBuild = false;
      this.newProjectState = undefined;
      this.currentProjectState = {};
      this.projectTabSelected = false;
      this.propertyTabSelected = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.addr-item {
  display: block;
  border: 1px solid #aaa;
  border-radius: 3px;
  padding: 5px 10px;
  margin: 3px 0;
}
.addr-details {
  display: none;
  transition: all 0.3s ease-in-out;
}
.addr-item:hover .addr-details {
  display: block;
}
</style>
